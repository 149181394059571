.trainings {
    display: flex;
    justify-content: space-around;
    margin: 100px;
    gap: 20px;
    min-height: 100vh;
    align-items: flex-start;
    align-items: center;
}

.training {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    border: 1px solid #c8a63d;
    border-radius: 8px;
    max-width: 600px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgb(0 0 0 / 38%);
    transition: transform 0.2s;
}

.training:hover {
    transform: scale(1.05);
}

.training-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: opacity 0.3s ease;
}

.watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0.7);
    font-size: 2em;
    font-weight: bold;
    pointer-events: none;
    transition: color 0.3s ease;
}

.training:hover .training-img {
    opacity: 0.4; 
}

.training:hover .watermark {
    color: rgb(249, 244, 244);
}

.training:hover .training {
    background-color: rgba(0, 0, 0, 0.9); 
}

.training-title {
    margin-top: 10px;
    text-align: center;
    color: #c8a63d;
    font-size: 30px;
}

@media (min-width: 601px) and (max-width: 1300px) {
    .training:hover {
        transform: scale(1.03); 
    }

    .watermark {
        font-size: 1.8em;
    }

    .training-img {
        border-radius: 8px;
    }

    .training-title {
        font-size: 28px;
    }
}

@media (max-width: 600px) {

    .trainings {
        flex-direction: column;
        margin: 20px;
        gap: 10px;
    }


    .training:hover {
        transform: scale(1.02);
    }

    .watermark {
        font-size: 1.5em;
    }

    .training-img {
        border-radius: 5px;
    }
}