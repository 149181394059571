.free-courses {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #0c0c0c81;
    margin: 0;
    box-sizing: border-box; 
}

.free-courses .main {
    padding: 0;
}

.coming-soon-text {
    font-size: 2.5em;
    text-align: center;
    color: #f0f0f0;
}

.workshop {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border: 1px solid #c8a63d;
    border-radius: 8px;
    max-width: 600px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgb(0 0 0 / 38%);
    cursor: pointer;
    transition: transform 0.2s;
}

.workshop:hover {
    transform: scale(1.05);
}

.workshop-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: opacity 0.3s ease;
    object-fit: cover; /* Asegura que la imagen cubra el contenedor sin distorsionarse */
}

.workshop:hover .workshop-img {
    opacity: 0.7; /* Ajusta la opacidad para que la imagen se aclare menos */
}

@media (min-width: 601px) and (max-width: 1300px) {
    .free-courses {
        padding: 30px;
    }

    .coming-soon-text {
        font-size: 2em; 
        padding: 15px;
    }

    .workshop-img {
        width: 90%; /* Ajusta el ancho de la imagen para pantallas medianas */
        max-width: 500px; /* Establece un ancho máximo para la imagen */
    }
}

@media (max-width: 600px) {
    .free-courses {
        flex-direction: column;
        padding: 20px;
    }

    .coming-soon-text {
        font-size: 1.5em;
        padding: 10px;
    }

    .workshop-img {
        width: 80%; /* Ajusta el ancho de la imagen para pantallas más pequeñas */
        max-width: 300px; /* Establece un ancho máximo para la imagen */
    }
}