.aboutUs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px;
    background-color: #0c0c0c7a;
  }
  
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: rgb(0 0 0 / 38%);
    border-radius: 10px;
    padding: 20px;
    color: white;
    width: 45%;
    box-sizing: border-box;
  }
  
  .section h2 {
    font-size: 1.7rem;
    color: #c8a63d;
  }
  
  .section ul {
    font-size: 1.3rem; 
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .section li {
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .section p {
    font-size: 1.3rem;
    text-align: justify;
  }
  
  .left {
    align-self: flex-start;
    text-align: left;
  }
  
  .right {
    align-self: flex-end;
    text-align: right;
    margin-top: 150px;
  }
  
  .right h2 {
    text-align: left;
  }

  @media (min-width: 601px) and (max-width: 1300px) {
    .aboutUs {
        padding: 40px;
    }

    .section {
        width: 48%; 
        padding: 15px;
    }

    .section h2 {
        font-size: 1.5rem;
    }

    .section ul {
        font-size: 1.2rem;
    }

    .section p {
        font-size: 1.2rem; 
    }

    .right {
        margin-top: 100px;
    }
}

@media (max-width: 600px) {
  .aboutUs {
      flex-direction: column;
      padding: 20px;
  }

  .section {
      width: 100%;
      padding: 10px;
  }

  .section h2 {
      font-size: 1.2rem;
  }

  .section ul {
      font-size: 1rem;
  }

  .section p {
      font-size: 1rem;
  }

  .right {
      margin-top: 0px;
  }
}