.home-container {
    display: flex;
    justify-content: space-around;
    margin: 100px;
    gap: 20px;
    min-height: 100vh;
    align-items: flex-start;
    align-items: center;
  }
  
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    border: 1px solid #c8a63d;
    border-radius: 8px;
    max-width: 600px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgb(0 0 0 / 38%);
    transition: transform 0.2s;
  }

  .image-container:hover {
    transform: scale(1.05);
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: opacity 0.3s ease;
  }
  
  .image-container h2 {
    margin-top: 10px;
    text-align: center;
    color: #c8a63d;
    font-size: 30px;
  }

  .image-container a {
    text-decoration: none;
  }

  @media (min-width: 601px) and (max-width: 1300px) {
    .home-container {
        margin: 50px;
        gap: 15px;
    }

    .image-container {
        max-width: 500px;
        padding: 8px;
    }

    .image-container h2 {
        font-size: 25px;
    }
}

@media (max-width: 600px) {
  .home-container {
      flex-direction: column;
      margin: 20px;
      gap: 10px;
  }

  .image-container {
      max-width: 100%;
      padding: 5px;
  }

  .image-container h2 {
      font-size: 20px;
  }
}
