.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: rgb(166 155 155 / 95%);
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-icon {
    width: 40px; /* Ajusta el tamaño según sea necesario */
    height: 40px; /* Ajusta el tamaño según sea necesario */
    margin-right: 10px;
  }
  
  .success-icon {
    color: green;
  }
  
  .error-icon {
    color: red;
  }