.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  position: relative;
  min-height: 100vh;
  background-color: #0c0c0c78;
}

#root::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../public/images/background.jpg') no-repeat center center;
  background-size: cover;
  background-color: #0c0c0c78;
  opacity: 0.7;
  z-index: -1;
}