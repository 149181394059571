.services {
    background-color: #0c0c0c7a;
}

.service {
    margin: 100px;
    display: flex;
    align-items: center;
    gap: 100px;
    margin-bottom: 20px;
}

.service-content {
    display: flex;
    flex-direction: column;
    width: 44%;
    background-color: rgb(0 0 0 / 38%);
    border-radius: 5px;
    padding: 20px;
}

.service-title {
    margin-bottom: 10px;
    text-align: center;
    color: #c8a63d;
}

.service-img {
    width: 45%;
    height: auto;
    border-radius: 10px;
    border: 1px solid #c8a63d;
}

.service-video {
    width: 80%; /* Ajusta el ancho del video */
    height: auto;
    border-radius: 10px;
    border: 1px solid #c8a63d; /* Borde dorado */
    margin: 20px auto 0; /* Añade margen superior y centra el video */
    display: block;
}

.service-texto {
    list-style-type: disc;
    padding-left: 30px;
    color: white;
    font-size: 1.3rem;
}

@media (min-width: 601px) and (max-width: 1300px) {
    .service {
        margin: 50px;
        gap: 50px;
        margin-bottom: 15px;
    }

    .service-content {
        width: 48%;
        padding: 15px;
    }

    .service-title {
        font-size: 1.5rem;
    }

    .service-img {
        width: 48%;
    }

    .service-texto {
        font-size: 1.2rem;
    }
}

@media (max-width: 600px) {
    .service {
        margin: 20px;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 10px;
    }

    .service-content {
        width: 100%;
        padding: 10px;
    }

    .service-title {
        font-size: 1.2rem;
    }

    .service-img {
        display: none;
    }

    .service-texto {
        font-size: 1rem;
    }
}