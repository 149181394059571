.footer {
  background-color: #232222;
  color: white;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.footer a {
  color: #bcaf7e;
  margin: 0 10px;
  text-decoration: none;
  font-size: 1.5em;
}

.footer a:hover {
  color: #c8a63d;
}

@media (min-width: 601px) and (max-width: 1300px) {
  .footer {
    padding: 15px 0;
  }

  .footer a {
    font-size: 1.3em;
    margin: 0 15px;
  }
}

@media (max-width: 600px) {
  .footer {
    padding: 10px 0;
  }

  .footer a {
    font-size: 1em;
    margin: 0 10px;
  }
}