.container-contact-us{
  display: flex;
  height: 100vh;
  min-height: 100vh;
  align-items: flex-start;
  align-items: center;
}

.contact-us {
    padding: 20px;
    background-color: rgb(0 0 0 / 48%);
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    margin-top: 10.6%;
    margin-bottom: 11%;
  }
  
  .contact-us h1 {
    color: #c8a63d;
    margin-bottom: 20px;
  }
  
  .contact-method {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 20px;
  }
  
  .contact-method h2 {
    color: #c8a63d;
    margin-right: 10px;
    margin-bottom: 0;
  }
  
  .contact-method p {
    color: #e4dede;
    font-size: 1.3rem;
    margin: 0;
  }

  .contact-method a {
    color: #e4dede;
    text-decoration: none;
  }

  @media (min-width: 601px) and (max-width: 1300px) {
    .contact-us {
        font-size: 16px;
        padding: 15px;
    }

    .contact-method p {
      font-size: 1.2rem;
    }

    .contact-method h2 {
      font-size: 1.3rem;
    }

}

  @media (max-width: 600px) {
    .contact-us {
        font-size: 14px;
        padding: 10px;
    }

    .contact-method p {
      font-size: 1rem;
    }

    .contact-method h2 {
      font-size: 1.1rem;
    }
}