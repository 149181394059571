/* Register.css */
.register-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  min-height: 100vh;
  align-items: flex-start;
  align-items: center;
}

.register-form {
  flex: 1;
  margin-right: 20px;
  background-color: rgb(0 0 0 / 48%);
  border-radius: 10px;
  padding: 20px;
}

.register-form h2 {
  margin-bottom: 20px;
  color: #c8a63d;
  text-align: center; 
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #e4dede;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  color: #5f5a5a;
}

.form-group input::placeholder {
  color: #666666;
}

.phone-input {
  display: flex;
  align-items: center;
}

.country-code-select {
  width: 30%!important;
  margin-right: 10px;
}

.cellphone-input {
  width: 70%; 
}

.register-container.waiting {
  cursor: wait;
}

.register-container {
  cursor: default;
}

button {
  padding: 10px 20px;
  font-size: 1.3rem;
  cursor: pointer;
  background-color: #c8a63d;
  color: white;
  border: none;
  border-radius: 5px;
}

button:hover {
  background-color: #c8a63d;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.divider {
  width: 2px;
  background-color: #e4dede;
  margin: 0 20px;
  height: auto;
  align-self: stretch;
}

.payment-steps {
  flex: 1;
  margin-left: 20px;
  background-color: rgb(0 0 0 / 48%);
  border-radius: 10px;
  padding: 20px;
  text-align: center; /* Centrar el contenido */
}

.payment-steps h2 {
  margin-bottom: 20px;
  color: #c8a63d;
}

.payment-steps h3 {
  margin-bottom: 15px;
  color: #c8a63d;
}

.payment-steps ol {
  color: #e4dede;
  padding-left: 0; /* Eliminar el padding izquierdo */
  list-style: none; /* Eliminar las viñetas */
  font-size: 1.2rem; /* Aumentar el tamaño de la fuente */
}

.payment-steps li {
  margin-bottom: 10px;
}

.payment-steps a {
  color: #e4dede;
  text-decoration: none;
}

.payment-icon {
  width: 100px;
  margin-right: 8px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin: 5px 0 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.payment-note {
  margin-top: 20px;
  font-size: 18px;
  font-style: bold;
  color: #c8a63d;
}

.no-list-style {
  list-style: none;
  padding-left: 0;
}

.error {
  color: red; /* Color rojo para los errores */
  font-size: 0.875rem; /* Tamaño de fuente más pequeño */
  margin-top: 5px; /* Espacio superior */
}

.custom-modal .modal-content {
  background-color: rgb(0 0 0 / 80%);
  color: #e4dede;
  border-radius: 10px;
  text-align: center;
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
  border: none;
  justify-content: center;
}

.custom-modal .modal-title {
  color: #c8a63d;
  width: 100%;
}

.custom-modal .modal-body {
  text-align: center;
}

.custom-modal .btn-secondary {
  background-color: #c8a63d;
  border: none;
  display: block;
  margin: 0 auto;
}

.custom-modal .btn-secondary:hover {
  background-color: #b08f2d;
}

.centered-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@media (min-width: 821px) {
  .register-container {
    flex-direction: row;
  }

  .register-form {
    margin-right: 20px;
    width: auto;
    margin-bottom: 0;
  }

  .divider {
    display: block;
  }

  .payment-steps {
    margin-left: 20px;
    width: auto;
  }
}

@media (max-width: 820px) {
  .register-container {
    flex-direction: column;
    padding: 10px;
  }

  .register-form {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media (max-width: 431px) {
  .register-container {
    flex-direction: column; /* Stack elements vertically */
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .register-form {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add bottom margin */
    width: 100%; /* Full width */
  }

  .payment-steps {
    margin-left: 0; /* Remove left margin */
    width: 100%; /* Full width */
  }

  .divider {
    display: none; /* Hide divider */
  }

  button {
    font-size: 0.9rem; /* Further reduce button font size */
    padding: 6px 12px; /* Further adjust button padding */
  }
}