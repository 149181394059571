.specialization-ml {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c0c0c7a;
}

.specialization-ml h1 {
  font-size: 4rem;
  color: #c8a63d;
  margin-bottom: 20px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 95%;
  margin: 30px;
  gap: 30px;
}

.text-section {
  flex: 1;
  padding-left: 20px;
  background-color: rgb(0 0 0 / 38%);
  border-radius: 10px;
}

.text-section h1,
.text-section h2 {
  font-size: 2rem;
  color: #c8a63d;
}

.text-section ul,
.text-section p {
  color: #f0f0f0;
  font-size: 1.3rem;
}

.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-section img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.banner-buttons-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  gap: 20px;
  background-color: #5f5c5c;
}

.banner {
  width: 40%;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  align-self: flex-start;
  padding: 20px;
  padding-left: 0px;
}

.inner-banner {
  width: 100%;
  height: 80%;
  background-color: #232222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-text {
  font-size: 2.2rem;
  color: white;
  animation: moveText 3s forwards;
}

@keyframes moveText {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(80%);
  }
}

.buttons {
  display: flex;
  gap: 20px;
}

.register-button,
.download-button {
  padding: 10px 20px;
  font-size: 1.3rem;
  cursor: pointer;
  background-color: #c8a63d;
  color: white;
  border: none;
  border-radius: 5px;
}

/* New styles for Module and Project components */
.module {
  margin-bottom: 20px;
}

.module h1 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.module ul {
  list-style-type: disc;
  padding-left: 20px;
}

.module li {
  margin-bottom: 5px;
}

.project {
  margin-bottom: 20px;
}

.project h2 {
  font-size: 1.25rem;
  margin-bottom: 5px;
}

.project p {
  margin: 0;
}

@media (min-width: 601px) and (max-width: 1300px) {
  .specialization-ml h1 {
    font-size: 3rem;
    margin-left: 15px;
  }

  .text-section h1,
  .text-section h2 {
    font-size: 1.8rem;
  }

  .text-section ul,
  .text-section p {
    font-size: 1.2rem;
  }

  .module h1 {
    font-size: 1.3rem;
  }

  .project h2,
  .register-button,
  .download-button {
    font-size: 1.1rem;
  }
}

@media (max-width: 1650px) {
  .animated-text {
    font-size: 2rem;
    animation: moveTextLaptop 3s forwards;
  }

  @keyframes moveTextLaptop {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(50%);
    }
  }
}

@media (max-width: 1200px) {
  .animated-text {
    font-size: 1.8rem;
    animation: moveTextSmall 3s forwards;
  }

  @keyframes moveTextSmall {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(40%);
    }
  }
}

@media (max-width: 912px) {
  .animated-text {
    font-size: 1.8rem;
    animation: moveTextSmall 3s forwards;
  }

  @keyframes moveTextSmall {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(10%);
    }
  }
}

@media (max-width: 600px) {
  .specialization-ml h1 {
    font-size: 2.3rem;
  }

  .text-section h1,
  .text-section h2 {
    font-size: 1.5rem;
  }

  .text-section ul,
  .text-section p {
    font-size: 1rem;
  }

  .module h1 {
    font-size: 1.2rem;
  }

  .project h2,
  .register-button,
  .download-button {
    font-size: 1rem;
  }

  .banner-buttons-container {
    justify-content: space-around;
  }

  .image-section,
  .banner {
    display: none;
  }
}

/* Sobrescribir estilos de ::after y ::before para banner e inner-banner */
div.banner::after,
div.banner::before,
div.inner-banner::after,
div.inner-banner::before {
  box-sizing: content-box !important;
}