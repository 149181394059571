.header {
  background-color: #232222;
  color: #c8a63d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative; 
}

.logo {
  font-size: 1.5em;
}

.menu {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-self: center;
}

.menu a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  font-size: 1.8em;
}

.menu a:hover {
  color: #c8a63d;
  text-decoration: none;
}

.menu a.active {
  color: #c8a63d;
}

.menu-button {
  display: none;
  font-size: 1.8em;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.menu.show {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #232222; 
  z-index: 1000;
  align-items: center;
}

@media (min-width: 601px) and (max-width: 1300px) {
  .header {
    padding: 8px 15px;
  }

  .logo {
    font-size: 1.3em;
  }

  .logo img {
    width: 250px;
  }


  .menu a {
    font-size: 1.5em;
    margin: 0 8px;
  }
}

@media (min-width: 601px) and (max-width: 853px) {
  .menu a {
    font-size: 1.4em;
    margin: 0 5px;
  }

}

@media (max-width: 600px) {
  .header {
    flex-direction: row;
    align-items: center;
    padding: 7px 12px;
  }

  .logo {
    font-size: 1.1em;
    margin-bottom: 0px;
  }

  .logo img {
    width: 250px;
  }

  .menu {
    display: none;
    flex-direction: column;
    width: auto;
    margin-right: 0;
  }

  .menu a {
    font-size: 1.2em;
    margin: 10px 0;
  }

  .menu-button {
    display: block;
    margin-left: auto;
  }

  .menu.show {
    display: flex;
  }
}